<template>
  <body class="email-section">
    <p>
      What follows is the Countable Web Productions official email signature.
      Copy the content below this line into gmail's settings in the email
      signature section.
      <span>
        Watch the tutorial here:
        <a href="https://www.youtube.com/watch?v=cmThunPasGQ">Link</a>
      </span>
    </p>

    <hr />
    <div style="padding:0 4px 0 0;">
      <a
        target="_blank"
        href="https://countable.ca"
        style='font-size: 15px; font-family:"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; letter-spacing: 1px; color: #393089'
      >
        <img
          src="/logo-sig.png"
          style="height:24px; padding: 0 5px 0 0;"
          alt="countable.ca"
        />
        COUNTABLE.CA</a
      >
    </div>

    <div
      style='font-size: 15px; font-family:"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; padding:5px 0; letter-spacing: 1px'
    >
      Clark Van Oyen, Director. 778.709.1230
    </div>
  </body>
</template>

<script>
export default {};
</script>

<style>
.email-section {
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.email-section p {
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 70%;
  margin-bottom: 2rem;
}
</style>
